/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { Layout, SEO } from "../components"
import { graphql } from "gatsby"
import { 
  SimpleHeroBanner, 
  HomeAbout, 
  Quote,
  EmbedVideo,
  Features,
  CTABanner
} from "../containers"

const IndexPage = ({ data }) => {
  const { markdownRemark: page } = data
  const ctas = page.frontmatter.callToActions
  const quotesection = page.frontmatter.quoteSection
  const ctabanner = page.frontmatter.ctabanner
  return (
    <Layout>
      <SEO title="Home" />
      <SimpleHeroBanner
        title={page.frontmatter.bannertitle}
        ctas={ctas}
      ></SimpleHeroBanner>
      
        <HomeAbout 
        sx={{
          pt: ["45px",null,"144px"],
          pb: ["22.5px",null,"72px"],
        }} 
        data={page.frontmatter.aboutSection}></HomeAbout>       
        <EmbedVideo 
        sx={{
          pt: ["45px",null,"72px"],
          pb: ["22.5px",null,"72px"],
        }}
        video={page.frontmatter.videourl}
        />
        <Quote 
        sx={{
          pt: ["45px",null,"72px"],
          pb: ["22.5px",null,"72px"],
        }}
        quote={quotesection.quote} author={quotesection.author}></Quote>
        <Features 
        sx={{
          pt: ["45px",null,"72px"],
          pb: "0",
        }}
        data={page.frontmatter.feature}></Features>    
      <CTABanner title={ctabanner.title} ctatext={ctabanner.ctatext} ctaurl={ctabanner.ctaurl} bgcolor="#f7f7f7"></CTABanner>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const query = graphql`
  {
    markdownRemark(frontmatter: { templateKey: { eq: "homepage" } }) {
      id
      frontmatter {
        bannertitle
        callToActions {
          firstCTA {
            ctatext
            linkURL
          }
          secondCTA {
            ctatext
            linkURL
          }
        }
        aboutSection {
          aboutimage
          description
          title
        }
        quoteSection {
          author
          quote
        }
        videourl
        feature {
          description
          title
          icon
        }
        ctabanner {
          ctatext
          ctaurl
          title
        }
      }
    }
  }
`
